import API from '../../stores/API';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { setCountry } from 'ReduxActions/countries';
import i18n from 'i18next';

export const SET_COMPETENCE_AREA = 'SET_COMPETENCE_AREA';
export const TYPE_LEGAL = 'LEGAL';
export const TYPE_FUNERAL = 'FUNERAL';
export const TYPE_THERAPY = 'THERAPY';
export const TYPE_CEREMONY = 'CEREMONY';
export const ALL_TYPES = 'ALL';

function recieveCompetenceArea (payload = null) {
  return {
    type: SET_COMPETENCE_AREA,
    payload
  };
}

export function getCompetenceAreas (filters = {}) {
  return async (dispatch, getState) => {
    let uri = '/competence-areas';

    const queryItems = {};

    if (filters.countryId) {
      queryItems.country_id = filters.countryId;
    }

    if (filters.type) {
      queryItems.type = filters.type;
    }

    if (Object.keys(queryItems).length) {
      const params = new URLSearchParams(queryItems);

      uri += `?${params.toString()}`;
    }

    const { data } = await API.GET(uri);

    return data;
  };
}

export function getCompetenceArea (id) {
  return async (dispatch) => {
    dispatch(recieveCompetenceArea());

    const { data, status, errors } = await API.GET(`/competence-areas/${id}?include=country`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    dispatch(setCountry(data.country));

    return dispatch(recieveCompetenceArea(data));
  };
}

export function updateCompetenceArea ({ id, name, type, duration, exclude_from_api, sort_value, countries }) { // eslint-disable-line camelcase
  return async (dispatch) => {
    const { data, status, errors } = await API.PUT(`/competence-areas/${id}`, { name, type, duration, exclude_from_api, sort_value, countries }); // eslint-disable-line camelcase

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    showNotice(i18n.t('The skill was updated'));
    return dispatch(recieveCompetenceArea(data));
  };
}

export function createCompetenceArea (params) {
  return async () => {
    const { data, status, errors } = await API.POST('/competence-areas', params);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return false;
    }

    showNotice(i18n.t('The skill was created'));
    return data;
  };
}

export function deleteCompetenceArea (id) {
  return async (dispatch) => {
    const { status, errors } = await API.DELETE(`/competence-areas/${id}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return false;
    }

    showNotice(i18n.t('The skill was deleted'));
    return dispatch(recieveCompetenceArea(null));
  };
}
