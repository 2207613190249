import React, { useEffect, useState, Suspense } from 'react';
import * as Sentry from "@sentry/react";
import ErrorBoundaryFallbackRender from './components/ErrorBoundaryFallbackRender';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import Spinner from './components/Spinner';
import { Switch } from 'react-router';
import LogIn from './views/Login';
import LogOut from './views/Logout';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Route from './components/Route';
import UserRegistry from './components/UserRegistry/UserRegistry';
import Dashboard from './views/Dashboard';
import CalendarView from './views/Calendar';
import SingleSupplierOrder from './views/SingleSupplierOrder';
import SingleLawyerOrder from './views/new/SingleLawyerOrder';
import SingleTombstoneOrder from './views/new/SingleTombstoneOrder';
import SingleTherapyOrder from './views/new/SingleTherapyOrder';
import SingleCeremonyOrder from './views/new/SingleCeremonyOrder';
import SuppliersArchiveView from './views/Suppliers';
import SingleSupplierView from './views/SingleSupplier';
import CountrySettings from './views/CountrySettings';
import CreateSupplierView from './views/CreateSupplier';
import SingleUserView from './views/SingleUser';
import CreateUser from './views/CreateUser';
import AdministrationsListView from './views/AdministrationList';
import SingleAdministrationsView from './views/SingleAdministration';
import NewAdministration from './views/NewAdministration';
import TranslateView from './views/Translate';
import SingleLocaleView from './views/SingleLocale';
import Export from './views/Export';
import Tests from './views/Tests';
import MyProfileView from './views/MyProfile';
import NotAuthorized from './views/NotAuthorized';
import WillsArchive from './views/WillsArchive';
import NotFound from './components/NotFound';
import { getLoggedInUser } from 'ReduxActions/users';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopBar from './components/TopBar';
import NavBar from './components/NavBar';
import OrderArchive from './views/OrderArchive';
import uniqueId from 'lodash/uniqueId';
import initializeIcons from './icons';
import CompetenceAreasArchive from './components/CompetenceAreas/CompetenceAreasArchive';
import CompetenceArea from './components/CompetenceAreas/CompetenceArea';
import CreateCompetenceArea from './components/CompetenceAreas/CreateCompetenceArea';
import BonusReport from './components/BonusReports/BonusReport';
import BonusReports from './components/BonusReports/BonusReports';
import NewSingleOrder from './views/new/SingleOrder';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import ReviewsArchive from './views/reviews/ReviewsArchive';

// const INTERCOM_APP_ID = 'tpr9kyvi'; // DEV
const INTERCOM_APP_ID = 'zvv5ohgk';
const fallbackRender = ({ error }) => <ErrorBoundaryFallbackRender error={error} />;

const AppMain = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoadingAuthenticatedUser, setIsLoadingAuthenticatedUser] = useState(true);
  const {
    isAuthenticated,
    isAdmin,
    isTombstoneAdvisor,
    isLegalAdvisor,
    isFuneralAdvisor,
    isSupplier,
    isTherapist,
    isOfficiant,
    loggedInUser
  } = useLoggedInUser();
  const { boot } = useIntercom();

  useEffect(() => {
    getAuthenticatedUser();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      document.body.classList.add('login');
      return;
    }

    document.body.classList.remove('login');
    const { name, email } = loggedInUser;
    boot({ name, email });
  }, [isAuthenticated]);

  // Initialize Font Awesome library.
  initializeIcons();

  async function getAuthenticatedUser () {
    await dispatch(getLoggedInUser());

    setIsLoadingAuthenticatedUser(false);
  }

  if (isLoadingAuthenticatedUser) {
    return <Spinner loading />;
  }

  if (!isAuthenticated) {
    return <LogIn />;
  }

  const props = {
    isAdmin,
    isTombstoneAdvisor,
    isLegalAdvisor,
    isFuneralAdvisor,
    isTherapist,
    isOfficiant,
    isSupplier
  };

  return (
    <Sentry.ErrorBoundary fallback={fallbackRender}>
      <TopBar {...props} />
      <NavBar />

      <div className='main app'>
        <Switch>
          <Route path='/logout' component={LogOut} />

          <AuthenticatedRoute
            all
            path='/anvandarregister'
            title={t('User register')}
            component={UserRegistry}
          />

          <AuthenticatedRoute
            path='/'
            component={Dashboard}
            exact
            funeral
            title={t('Home')}
            key={uniqueId('funeral')}
          />
          <AuthenticatedRoute
            path='/therapy-stats'
            component={Dashboard}
            therapist
            title={t('Home')}
            orderType='App\TherapyOrder'
            key={uniqueId('therapy')}
          />
          <AuthenticatedRoute
            path='/ceremony-stats'
            component={Dashboard}
            therapist
            title={t('Home')}
            orderType='App\CeremonyOrder'
            key={uniqueId('ceremony')}
          />
          <AuthenticatedRoute
            path='/juridik-stats'
            component={Dashboard}
            lawyer
            title={t('Home')}
            orderType='App\LawyerOrder'
            key={uniqueId('legal')}
          />
          <AuthenticatedRoute
            path='/gravsten-stats'
            component={Dashboard}
            tombstone
            title={t('Home')}
            orderType='App\TombstoneOrder'
            key={uniqueId('tombstone')}
          />

          <AuthenticatedRoute
            path='/kalender'
            title={t('Calendar')}
            {...props}
            component={CalendarView}
            all
          />

          <AuthenticatedRoute
            exact
            available
            isFuneralOrder
            path='/ordrar'
            title={t('New orders')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isFuneralOrder
            path='/ordrar/pagaende'
            title={t('Orders in progress')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            archives
            isFuneralOrder
            path='/ordrar/arkiv'
            title={t('Archived orders')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            deleted
            admin
            isFuneralOrder
            path='/ordrar/raderade'
            title={t('Deleted orders')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isSearch
            isFuneralOrder
            path='/ordrar/sok'
            title={t('Search')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            title={t('Orders')}
            path='/ordrar/order/:id/:tab?'
            component={NewSingleOrder}
          />
          <AuthenticatedRoute
            path='/ordrar/supplier-order/:id'
            title={t('Orders')}
            component={SingleSupplierOrder}
            supplier
          />

          <AuthenticatedRoute
            exact
            available
            isLawyerOrder
            path='/juridik'
            title={t('New orders')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isLawyerOrder
            key={uniqueId()}
            title={t('Orders in progress')}
            path='/juridik/pagaende'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            archives
            isLawyerOrder
            key={uniqueId()}
            title={t('Archived orders')}
            path='/juridik/arkiv'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            deleted
            admin
            isLawyerOrder
            key={uniqueId()}
            title={t('Deleted orders')}
            path='/juridik/raderade'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isSearch
            isLawyerOrder
            key={uniqueId()}
            title={t('Search')}
            path='/juridik/sok'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            lawyer
            title={t('Orders')}
            path='/juridik/:id/:tab?'
            component={SingleLawyerOrder}
          />

          <AuthenticatedRoute
            exact
            available
            isTherapyOrder
            path='/therapy'
            title={t('New orders')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isTherapyOrder
            key={uniqueId()}
            title={t('Orders in progress')}
            path='/therapy/pagaende'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            archives
            isTherapyOrder
            key={uniqueId()}
            title={t('Archived orders')}
            path='/therapy/arkiv'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            deleted
            admin
            isTherapyOrder
            key={uniqueId()}
            title={t('Deleted orders')}
            path='/therapy/raderade'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isSearch
            isTherapyOrder
            key={uniqueId()}
            title={t('Search')}
            path='/therapy/sok'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            therapist
            title={t('Orders')}
            path='/therapy/:id/:tab?'
            component={SingleTherapyOrder}
          />

          <AuthenticatedRoute
            exact
            available
            isCeremonyOrder
            path='/ceremonies'
            title={t('New orders')}
            key={uniqueId()}
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isCeremonyOrder
            key={uniqueId()}
            title={t('Orders in progress')}
            path='/ceremonies/pagaende'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            archives
            isCeremonyOrder
            key={uniqueId()}
            title={t('Archived orders')}
            path='/ceremonies/arkiv'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            deleted
            admin
            isCeremonyOrder
            key={uniqueId()}
            title={t('Deleted orders')}
            path='/ceremonies/raderade'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isSearch
            isCeremonyOrder
            key={uniqueId()}
            title={t('Search')}
            path='/ceremonies/sok'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            officiant
            title={t('Orders')}
            path='/ceremonies/:id/:tab?'
            component={SingleCeremonyOrder}
          />

          <AuthenticatedRoute
            exact
            available
            isTombstoneOrder
            key={uniqueId()}
            title={t('New orders')}
            path='/gravstenar'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isTombstoneOrder
            key={uniqueId()}
            title={t('Orders in progress')}
            path='/gravstenar/pagaende'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            archives
            isTombstoneOrder
            key={uniqueId()}
            title={t('Archived orders')}
            path='/gravstenar/arkiv'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            deleted
            admin
            isTombstoneOrder
            key={uniqueId()}
            title={t('Deleted orders')}
            path='/gravstenar/raderade'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isSearch
            isTombstoneOrder
            key={uniqueId()}
            title={t('Search')}
            path='/gravstenar/sok'
            component={OrderArchive}
          />
          <AuthenticatedRoute
            exact
            isTombstoneOrder
            title={t('Orders')}
            path='/gravstenar/:id/:tab?'
            component={SingleTombstoneOrder}
          />

          <AuthenticatedRoute
            path='/leverantorer'
            title={t('Suppliers')}
            component={SuppliersArchiveView}
            exact
            allExceptSuppliers
          />
          <AuthenticatedRoute
            title={t('Supplier')}
            path='/leverantorer/leverantor/:id'
            component={SingleSupplierView}
            allExceptSuppliers
          />
          <AuthenticatedRoute
            title={t('Create supplier')}
            path='/leverantorer/skapa'
            component={CreateSupplierView}
            admin
          />

          <AuthenticatedRoute
            title={t('Reports')}
            path='/ersattningsrapporter'
            component={BonusReports}
            admin
            exact
          />
          <AuthenticatedRoute
            title={t('Reports')}
            path='/ersattningsrapporter/user/:userId'
            component={BonusReports}
            exact
          />
          <AuthenticatedRoute
            title={t('Reports')}
            path='/ersattningsrapporter/:id'
            component={BonusReport}
            exact
          />

          <AuthenticatedRoute
            title={t('User')}
            path='/anvandare/id/:id'
            component={SingleUserView}
            admin
            exact
          />
          <AuthenticatedRoute
            title={t('Create user')}
            path='/anvandare/skapa'
            component={CreateUser}
            admin
            exact
          />
          <AuthenticatedRoute
            title={t('Settings')}
            path='/settings/:code'
            component={CountrySettings}
            admin
            exact
          />
          <AuthenticatedRoute
            title={t('Tasks')}
            path='/tasks'
            component={AdministrationsListView}
            exact
          />
          <AuthenticatedRoute
            title={t('Admin task')}
            path='/tasks/task/:id'
            component={SingleAdministrationsView}
            exact
          />
          <AuthenticatedRoute
            title={t('Create admin task')}
            path='/tasks/task'
            component={NewAdministration}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Translate')}
            path='/translate'
            component={TranslateView}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Translate')}
            path='/translate/:fileName'
            component={SingleLocaleView}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Export')}
            path='/export'
            component={Export}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Tests')}
            path='/tests'
            component={Tests}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Profile')}
            path='/profil'
            component={MyProfileView}
            all
          />

          <AuthenticatedRoute
            title={t('Skills')}
            path='/kompetenser'
            component={CompetenceAreasArchive}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Skills')}
            path='/kompetenser/skapa'
            component={CreateCompetenceArea}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Skills')}
            path='/kompetenser/:id'
            component={CompetenceArea}
            exact
            admin
          />

          <AuthenticatedRoute
            title={t('Wills')}
            path='/wills'
            component={WillsArchive}
            exact
            admin
          />
          <AuthenticatedRoute
            title={t('Wills')}
            path='/wills/sok'
            component={WillsArchive}
            exact
            admin
            isSearch
          />

          <AuthenticatedRoute
            title={t('Wills')}
            path='/wills/archive'
            component={WillsArchive}
            exact
            admin
            archived
          />

          <AuthenticatedRoute
            path='/reviews'
            title={t('Reviews')}
            component={ReviewsArchive}
            exact
            adminOrAdvisor
          />

          <AuthenticatedRoute
            title={t('Access denied')}
            path='/403'
            component={NotAuthorized}
            exact
            all
          />

          <AuthenticatedRoute title='404' component={NotFound} all />
        </Switch>
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default function WrappedApp () {
  return (
    <Suspense fallback='...loading'>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
      >
        <AppMain />
      </IntercomProvider>
    </Suspense>
  );
}
