import React, { useEffect, useState } from 'react';
import MeetingDayGroup from './MeetingDayGroup';
import useUser from 'ReduxHooks/useUser';
import { Toggle, Input } from 'FormElements';
import { Notice } from 'Layout';
import { useTranslation, Trans } from 'react-i18next';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import If from 'Conditionals/If';

const UserMeetingTimes = () => {
  const { t } = useTranslation();
  const { user, updateField } = useUser();
  const { getCountryCode } = useSingleOrder();
  const userProfiles = user.user_profiles || [];
  const activeProfiles = userProfiles.filter(profile => profile.active === true);
  const [meetingTimes, setMeetingTimes] = useState(user.schedule);
  const [phoneHours, setPhoneHours] = useState(user.phone_hours);
  const [scheduleToggle, setScheduleToggle] = useState(user.no_schedule);
  const updateGroup = (index, group, updatePhoneHours = false) => {
    const times = updatePhoneHours ? { ...phoneHours } : { ...meetingTimes };
    times[index] = group;

    if (updatePhoneHours) {
      setPhoneHours(times);
    } else {
      setMeetingTimes(times);
    }
  };

  /**
   * Filter out all the junk or empty values for the meeting times.
   * @return {Object}
   */
  function getSanitizedTimes (isPhoneHours = false) {
    const sanitized = {};
    const times = isPhoneHours ? phoneHours : meetingTimes;

    Object.keys(times).map(key => {
      sanitized[key] = times[key].filter(({ startTime, endTime }) => {
        if (!startTime || startTime === '00:00') {
          return false;
        }

        if (!endTime || endTime === '00:00') {
          return false;
        }

        return true;
      });
    });

    return sanitized;
  }

  const shouldDeactivateSchedule = meetingTimes => !Object.values(meetingTimes).some(daySlots => daySlots.some(slot => !slot.inactive));

  useEffect(() => {
    const schedule = getSanitizedTimes();
    updateField('schedule', schedule);
  }, [meetingTimes]);

  useEffect(() => {
    const schedule = getSanitizedTimes(true);
    updateField('phone_hours', schedule);
  }, [phoneHours]);

  useEffect(() => {
    updateField('no_schedule', scheduleToggle);
  }, [scheduleToggle]);

  function setMinimumNoticePeriod (value) {
    if (value === '') {
      value = null;
    }

    updateField('minimum_notice_period', value);
  }

  return (
    <div className='user-meeting-times'>
      <Notice
        hideIcon
      >
        <h4>{t('Your rolling schedule')}</h4>

        <If condition={getCountryCode() === 'se'}>
          <p>
            {activeProfiles.length === 1 && (
              <Trans profileUrl={activeProfiles[0]?.url ? activeProfiles[0].url : ''}>
                Here you set your rolling schedule of meeting times, these repeat weekly. Times refer to when you are available for appointment bookings. When you click on save, the next 3 days will be visible on your <a href={activeProfiles[0].url} target='_blank' rel='noopener noreferrer'>profile page on Lavendla.se</a>. You can always go back and change these times. After 5 p.m., any saved times will be hidden the following morning, so that you can get back on time.
              </Trans>
            )}
            {activeProfiles.length > 1 && t('Here you set your rolling schedule of meeting times, these repeat weekly. Times refer to when you are available for appointment bookings. When you click on save, the next 3 days will be visible on your profile pages. You can always go back and change these times. After 5 p.m., any saved times will be hidden the following morning, so that you can get back on time.')}
          </p>
          <p>
            <strong>{t('N.B.')}</strong> {t('When a customer books an appointment with you, you will receive an SMS about this and a new order is created for you in Admin. Be very careful to keep your schedule up to date. We take the display of incorrect times seriously because they create unhappy customers and extra work for everyone.')}
          </p>
        </If>
        <br />
        <Toggle
          label={t('Activate the entire week')}
          defaultChecked={!scheduleToggle}
          onChange={() => setScheduleToggle(!scheduleToggle)}
        />
      </Notice>
      <Notice hideIcon>
        <h4>{t('Time between meetings')}</h4>
        <p>{t('Here you can set the time between your meetings.')}<br />
          {t('The time between phone/video meetings is also used for meetings synced from your Google Calendar.')}
        </p>
        <br />
        <div className='row new-form'>
          <div className='col-6'>
            <Input
              floatingLabel
              onChange={value => updateField('physical_meeting_break_time', value)}
              label={t('Time between physical meetings (minutes)')}
              value={user.physical_meeting_break_time}
              type='text'
            />

            <Input
              floatingLabel
              onChange={value => updateField('online_meeting_break_time', value)}
              label={t('Time between phone/video meetings (minutes)')}
              value={user.online_meeting_break_time}
              type='text'
            />

            <Input
              type='number'
              label={t('Minimum notice period')}
              value={user.minimum_notice_period}
              min={0}
              onChange={value => setMinimumNoticePeriod(value)}
              floatingLabel
            />
          </div>
        </div>
      </Notice>

      <div className='user-meeting-times-container'>
        {Object.keys(meetingTimes).map(keyName => (
          <MeetingDayGroup
            key={keyName}
            user={user}
            groupIndex={parseInt(keyName)}
            scheduledTimes={meetingTimes[keyName]}
            scheduledPhoneHours={phoneHours[keyName]}
            updateGroup={updateGroup}
            noSchedule={user.no_schedule}
            allowPhoneHours={user.allow_phone_hours}
          />
        ))}
      </div>
      <If condition={shouldDeactivateSchedule(meetingTimes) && !scheduleToggle}>
        <Notice
          type='warning'
        >
          <h4>{t('You have an active schedule but no active working hours')}</h4>
          <p>
            {t('Please activate at least one time slot for each day, or deactivate your schedule.')}
          </p>
          <br />
        </Notice>
      </If>
    </div>

  );
};

export default UserMeetingTimes;
