import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  parse,
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfDay,
  endOfDay,
  setHours,
  addDays,
  isValid
} from 'date-fns';
import { sv, da, enGB } from 'date-fns/locale';
import store from '../stores/RootStore';
import appointmentsStore from '../stores/appointments';
import AppointmentTitle from '../components/AppointmentTitle';
import Toolbar from '../components/Calendar/Toolbar';
import Spinner from '../components/Spinner';
import Modal from 'react-modal';
import EventSummery from '../components/EventSummery';
import ReactSVG from 'react-svg';
import { useTranslation } from 'react-i18next';
import { getDatesForSetTimezone, getUTCOffset } from 'Library/dateFunctions';

const getLocale = (countryCode) => {
  switch (countryCode.toLowerCase()) {
    case 'se':
      return {
        'sv-SE': sv
      };
    case 'dk':
      return {
        'da-DK': da
      };
    default:
      return {
        'en-GB': enGB
      };
  }
};

const Calendar = ({ isSupplier, history }) => {
  const { t } = useTranslation();
  const { isFuneralAdvisor, isAdmin, loggedInUser: { id: loggedInUserId }, loggedInUserCountry } = useLoggedInUser();
  const search = new URLSearchParams(window.location.search);
  const [currentView, setCurrentView] = useState(search.get('view') || 'week');
  const [type, setType] = useState(search.get('type') || '');
  const [user, setUser] = useState(search.get('assigned_to') ? parseInt(search.get('assigned_to'), 10) : (isAdmin ? null : loggedInUserId));
  const [currentDate, setCurrentDate] = useState(
    isValid(parse(search.get('date') || '', 'yyyy-MM-dd', new Date()))
      ? parse(search.get('date'), 'yyyy-MM-dd', new Date())
      : new Date()
  );
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [clickedEvent, setClickedEvent] = useState(null);
  const [country, setCountry] = useState(isAdmin ? {} : loggedInUserCountry || {});

  const supplierUserId = isSupplier ? loggedInUserId : null;
  const locale = getLocale(loggedInUserCountry?.code);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    getDay: date => date.getDay(),
    locales: locale
  });

  useEffect(() => {
    if (isAdmin && user) {
      setUser(null);
    } else {
      updateAppointments(currentDate, currentView);
    }
  }, [country]);

  useEffect(() => {
    updateAppointments(currentDate, currentView);
  }, [user, type, supplierUserId]);

  const getDates = (date, view) => {
    let start, end;
    switch (view) {
      case 'month':
        start = startOfMonth(date);
        end = endOfMonth(date);
        break;
      case 'week':
        start = startOfWeek(date, { weekStartsOn: 1 });
        end = endOfWeek(date, { weekStartsOn: 1 });
        break;
      case 'day':
        start = startOfDay(date);
        end = endOfDay(date);
        break;
      default:
        start = startOfDay(date);
        end = addDays(start, 1);
        break;
    }
    return { start, end };
  };

  const updateRange = (date, view) => {
    setCurrentDate(date);
    updateAppointments(date, view);
  };

  const changeDate = (date, view) => {
    setCurrentDate(date);
    updateRange(date, view);
  };
  const changeView = (view) => {
    setCurrentView(view);
    updateRange(currentDate, view);
  };

  const updateAppointments = useCallback((date = currentDate, view = currentView) => {
    const { start, end } = getDates(date, view);

    const params = {
      type,
      user_id: user,
      limit: 'all',
      start_date: format(start, 'yyyy-MM-dd'),
      end_date: format(end, 'yyyy-MM-dd'),
      with_archived: 1,
      supplier_user_id: supplierUserId,
      country_id: country?.id
    };

    updateHistory();
    setIsLoading(true);

    appointmentsStore.list(params).then(response => {
      setIsLoading(false);
      if (response.status === 'error') {
        store.noticeStore.showNotice(response.errors.error_message, 'notice');
      } else {
        setAppointments(response.data);
      }
    });
  }, [user, type, supplierUserId, country]);

  const updateHistory = () => {
    const date = format(currentDate, 'yyyy-MM-dd');
    let url = `/kalender/?view=${currentView}&date=${date}`;

    if (!isSupplier && user) {
      url += `&assigned_to=${user}`;
    }

    if (type !== '') {
      url += `&type=${type}`;
    }

    if (country?.id) {
      url += `&country=${country.id}`;
    }

    history.push(url);
  };

  const getAppointmentTitle = appointment => <AppointmentTitle appointment={appointment} />;

  const onEventClick = (event) => {
    setIsEventModalOpen(true);
    setClickedEvent(event);
  };

  const calendarList = appointments.map(appointment => {
    const [start, end] = getDatesForSetTimezone(appointment.start_date, appointment.end_date);

    return {
      title: getAppointmentTitle(appointment),
      start,
      end,
      appointment
    };
  });

  const meetingTypes = {
    customer_meeting: t('Customer meeting'),
    other: t('Other')
  };

  if (isFuneralAdvisor || isAdmin) {
    Object.assign(meetingTypes, {
      memorial: t('Memorial service'),
      ceremony: t('Ceremony'),
      entombment: t('Entombment'),
      viewing: t('Viewing')
    });
  }

  return (
    <div className='calendar'>
      <div className={isLoading ? 'calender-container loading' : 'calender-container'}>
        {isLoading && <Spinner />}
        <BigCalendar
          eventPropGetter={event => ({ className: `type-${event.appointment.type}` })}
          tooltipAccessor={() => null}
          localizer={localizer}
          culture={Object.keys(locale)[0]}
          components={{
            toolbar: props => (
              <Toolbar
                {...props}
                user={user}
                country={country}
                setCountry={setCountry}
                updateStaffFilterState={setUser}
                updateTypeSelector={({ target: { value } }) => setType(value)}
                meetingTypes={meetingTypes}
                type={type}
              />
            )
          }}
          events={calendarList}
          views={['month', 'week', 'day']}
          view={currentView}
          onView={changeView}
          onNavigate={changeDate}
          scrollToTime={setHours(startOfDay(new Date()), 8)}
          onSelectEvent={onEventClick}
          date={currentDate}
        />
      </div>

      <p>{t('Times are shown for the time zone')}: <em>{timeZone} (UTC{getUTCOffset(timeZone)})</em></p>

      <ul className='calendar-color-codes'>
        {Object.keys(meetingTypes).map(key => (
          <li key={key}>{meetingTypes[key]}<span className={`calendar-color-codes__color ${key}`} /></li>
        ))}
      </ul>

      <Modal
        className='event-modal'
        isOpen={isEventModalOpen}
        contentLabel=''
        onRequestClose={() => setIsEventModalOpen(false)}
      >
        {clickedEvent && (
          <>
            <div className='header'>
              <ReactSVG src='/icons/icon-close-black.svg' onClick={() => setIsEventModalOpen(false)} />
            </div>
            <EventSummery event={clickedEvent} meetingTypes={meetingTypes} />
          </>
        )}
      </Modal>
    </div>
  );
};

Calendar.propTypes = {
  history: PropTypes.object.isRequired,
  isSupplier: PropTypes.bool,
  country: PropTypes.object
};

export default Calendar;
